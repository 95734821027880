<template>
  <div>
<!--    <router-view></router-view>-->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: "screeningHome"
}
</script>

<style scoped>

</style>
